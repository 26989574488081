import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';
import G from './G';
import I from './I';
import I2 from './I2';
import I3 from './I3';
import X from './X';
import R from './R';
import Q from './Q';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  // Fetch the user's location
  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);

  // Countdown timer logic
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the countdown timer
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="landing-container">
              {/* Video Background */}
              <video
                autoPlay
                playsInline
                preload="auto"
                loop
                muted
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  objectFit: 'cover',
                  zIndex: -1,
                }}
              >
                <source src="./images/background3.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              {/* Main content */}
              <div className="container">
                <a className="header-logo" href="https://onlyfans.com/melbrancho/c13">
                  <div className="onlyfans"></div>
                </a>
                <img
                  src="https://i.ibb.co/TrXVg2N/photo-2024-09-11-15-52-29-modified.png"
                  alt="Header Image"
                  className="header-image"
                />

                <div className="textContainer">
                  <section>
                    <div className="username"><strong>skimask0goddess</strong></div>
                    <div className="useraccountname">@skimask0goddess</div>

                  </section>
                </div>
                <div className="textContainer2">
                  <div className="time-in-current-location">
                  <strong>📍 {city}, {country} {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
            />} ❤️25 years ♋ Cancer<br/>
            🟢 Online Now<br/><br/>

            Get exclusive access to my content and interact with me directly. <br/><br/>

            🍑 💦 Do you live near {city}?📍 DM me about making content 😉 🍑 🔥</strong>

                  </div>
                </div>
                <div className="textContainer3">
                  <a href="https://onlyfans.com/melbrancho/c13" id="customButton">
                    <div className="onlyfans"></div>
                    My Onlyfans page 🔞
                  </a>
                </div>



                <Analytics />
              </div>
            </div>
          }
        />
        <Route path="/I-Am-Waiting-For-You/onlyfans.com/melbrancho" element={<G />} />
        <Route path="/onlyfans.com/melbrancho" element={<G />} />
        <Route path="/s" element={<X />} />
        <Route path="/allmylinks.com" element={<I />} />
        <Route path="/allmylinks.com/1" element={<I2 />} />
        <Route path="/allmylinks.com/2" element={<R />} />
        <Route path="/allmylinks.com/3" element={<Q />} />
        <Route path="/allmylinks.com/4" element={<I3 />} />
      </Routes>
    </Router>
  );
}

export default App;
